import { Injectable } from '@angular/core'
import { PageSettingDto } from '@api/dto'

import { HttpService } from './http.service'

@Injectable()
export class PageSettingService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pageId: string) {
    return await this.httpService.get<PageSettingDto[]>(`/admin/v1/page/${pageId}/settings`)
  }

  async update(pageId: string, settingId: string, pageSettingDto: PageSettingDto) {
    return await this.httpService.put<PageSettingDto>(`/admin/v1/page/${pageId}/settings/${settingId}`, pageSettingDto)
  }
}
