import { Injectable } from '@angular/core'

import _ from 'lodash'

import { HttpService } from './http.service'
import { CreateCategoryDto, UpdateCategoryDto, CategoryDto, PageResultDto } from '@api/dto'
import { PagingDto } from '@api/dto/paging.dto'

@Injectable()
export class CategoryService {
  constructor(
    private httpService: HttpService) {
    
  }
  
  async find(searchText: string, { offset, limit }: PagingDto) {
    return await this.httpService.get<PageResultDto<CategoryDto>>('/admin/v1/category', { searchText, offset, limit })
  }

  async findOne(id: string) {
    return await this.httpService.get<CategoryDto>(`/admin/v1/category/${id}`)
  }

  async findLeave(searchText: string, { offset, limit }: PagingDto) {
    return await this.httpService.get<PageResultDto<CategoryDto>>('/admin/v1/category/leaves', { searchText, offset, limit })
  }

  async create({ name, parentId, iconImageId, activeIconImageId}: CreateCategoryDto) {
    return await this.httpService.post<boolean>('/admin/v1/category', { name, parentId, iconImageId, activeIconImageId })
  }

  async update(categoryId: string, { name, parentId, iconImageId, activeIconImageId }: UpdateCategoryDto) {
    return await this.httpService.put<boolean>(`/admin/v1/category/${categoryId}`, { name, parentId, iconImageId, activeIconImageId })
  }

  async delete(categoryId: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/category/${categoryId}`)
  }
}
