import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PostDto, PageResultDto, FeaturedPostDto, CreateFeaturedPostDto, UpdateFeaturedPostDto } from '@api/dto'

@Injectable()
export class FeaturedPostService {
  constructor(
    private httpService: HttpService
  ) {}

  async reOrder(postIds: string[]) {
    return await this.httpService.put<Boolean>('/admin/v1/post/featured/reorder', { postIds })
  }

  async create({ postId }: CreateFeaturedPostDto) {
    return await this.httpService.post<Boolean>('/admin/v1/post/featured', { postId })
  }

  async find() {
    return await this.httpService.get<PageResultDto<FeaturedPostDto>>('/admin/v1/post/featured')
  }

  async update(id: string, {title, content, thumbnailId}: UpdateFeaturedPostDto) {
    return this.httpService.put<Boolean>(`/admin/v1/post/featured/${id}`, {title, content, thumbnailId})
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/post/featured/${id}`)
  }
}