import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { StoreDto, PageResultDto, FindStoreDto, StoreDetailDto, CreateOrUpdateStoreDto } from '@api/dto'

@Injectable()
export class StoreService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findStoreDto: FindStoreDto) {
    return await this.httpService.get<PageResultDto<StoreDto>>('/admin/v1/store', findStoreDto)
  }

  async findOne(id: string) {
    return this.httpService.get<StoreDetailDto>(`/admin/v1/store/${id}`)
  }

  async create({ name, phoneNumber, street, city, district }: CreateOrUpdateStoreDto) {
    return this.httpService.post<StoreDetailDto>(`/admin/v1/store`, {name, phoneNumber, street, city, district })
  }

  async update(id: string, { name, phoneNumber, street, city, district }: CreateOrUpdateStoreDto) {
    return this.httpService.put<StoreDetailDto>(`/admin/v1/store/${id}`, { name, phoneNumber, street, city, district })
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/store/${id}`)
  }
}