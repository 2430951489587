import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { ProductDto, PageResultDto, FindProductDto, ProductDetailDto, CreateOrUpdateProductDto, SetFilesDto, SetFileDto, ResultDto, CreateOrUpdateAdvantageDto } from '@api/dto'

@Injectable()
export class ProductService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>('/admin/v1/product', findProductDto)
  }

  async findOne(id: string) {
    return this.httpService.get<ProductDetailDto>(`/admin/v1/product/${id}`)
  }

  async create({name, description, productTypeId, productCategoryId, thumbnailId, advantageImageId, buyLink, weight, age, isHotSell, sizes, metaTag, autoMetaTag }: CreateOrUpdateProductDto) {
    return this.httpService.post<ProductDetailDto>(`/admin/v1/product`, {name, description, productTypeId, productCategoryId, thumbnailId, advantageImageId, buyLink, weight, age, isHotSell, sizes, metaTag, autoMetaTag })
  }

  async update(id: string, {name, description, productTypeId, productCategoryId, thumbnailId, advantageImageId, buyLink, weight, age, isHotSell, sizes, metaTag, autoMetaTag }: CreateOrUpdateProductDto) {
    return this.httpService.put<ProductDetailDto>(`/admin/v1/product/${id}`, {name, description, productTypeId, productCategoryId, thumbnailId, advantageImageId, buyLink, weight, age, isHotSell, sizes, metaTag, autoMetaTag })
  }

  async setImages(productId: string, setFilesDto: SetFilesDto) {
    return await this.httpService.put<ProductDetailDto>(`/admin/v1/product/${productId}/images`, setFilesDto)
  }

  async setThumbnail(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/admin/v1/product/${productId}/thumbnail`, setFileDto)
  }

  async enabled(id: string) {
    return this.httpService.put(`/admin/v1/product/${id}/enabled`)
  }

  async disabled(id: string) {
    return this.httpService.put(`/admin/v1/product/${id}/disabled`)
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/product/${id}`)
  }

  async createAdvantage(productId: string ,{ imageId, detail }: Partial<CreateOrUpdateAdvantageDto>)  {
    return this.httpService.post<ResultDto>(`/admin/v1/product/${productId}/advantages`, { 
      imageId,
      detail   
    })
  }

  async updateAdvantage(productId: string, productAdvantageId: string, UpdateAdvantage: CreateOrUpdateAdvantageDto) {
    return await this.httpService.put<ResultDto>(`/admin/v1/product/${productId}/advantages/${productAdvantageId}`, UpdateAdvantage)
  }

  async deleteAdvantage(productId:string, productAdvantageId: string) {
    return this.httpService.delete(`/admin/v1/product/${productId}/advantages/${productAdvantageId}`)
  }
}