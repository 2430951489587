import { Injectable } from '@angular/core'

import * as _ from 'lodash'

// import { RestaurantDto } from '@foodbuzz/dto/restaurant.dto'
// import { RecommendDto, EditorDto, BannerDto } from '@foodbuzz/dto'
import { BannerDto, FaqDetailDto } from '@api/dto'
import { BannerDetailDto } from '@api/dto/banner.dto'
import { CertificationDetailDto } from '@api/dto/certification.dto'
import { ReviewDetailDto } from '@api/dto/review.dto'


@Injectable()
export class ValidateService {
  constructor( ) { }

  pickPrimitiveValue(object : Object){
    return _.pickBy(object, o => {
      return this.validatePrimitive(o)
    })
  }

  validatePrimitive(o): boolean {
    const hello = _.isNull(o) ||  _.isBoolean(o) || _.isNumber(o)  || (_.isString(o) && o.length > 0)
    return hello
  }

  filterBanner(banner: BannerDto){
    return _.pickBy(banner, (o) => {
      return this.validatePrimitive(o) || (_.isArray(o) && o.length) || (_.isObject(o) && !_.isEmpty(this.pickPrimitiveValue(o))) || _.isDate(o)
    })
  }

  filterCertification(certification: CertificationDetailDto){
    return _.pickBy(certification, (o) => {
      return this.validatePrimitive(o) || (_.isArray(o) && o.length) || (_.isObject(o) && !_.isEmpty(this.pickPrimitiveValue(o))) || _.isDate(o)
    })
  }
  filterReview(review: ReviewDetailDto){
    return _.pickBy(review, (o) => {
      return this.validatePrimitive(o) || (_.isArray(o) && o.length) || (_.isObject(o) && !_.isEmpty(this.pickPrimitiveValue(o))) || _.isDate(o)
    })
  }
  filterFaq(faq: FaqDetailDto){
    return _.pickBy(faq, (o) => {
      return this.validatePrimitive(o) || (_.isArray(o) && o.length) || (_.isObject(o) && !_.isEmpty(this.pickPrimitiveValue(o))) || _.isDate(o)
    })
  }
}