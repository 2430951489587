import { Injectable } from '@angular/core'

import { PageResultDto, PostDto, PostDetailDto, CreateOrUpdatePostDto, FindPostDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class PostService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPostDto: FindPostDto) {
    return await this.httpService.get<PageResultDto<PostDto>>('/admin/v1/post', findPostDto)
  }

  async findOne(id: string) {
    return this.httpService.get<PostDetailDto>(`/admin/v1/post/${id}`)
  }

  async create({title, content, thumbnailId, categoryId, authorId, metaTag, autoMetaTag, createdBy }: CreateOrUpdatePostDto) {
    return this.httpService.post<PostDetailDto>(`/admin/v1/post`, {title, content, thumbnailId, categoryId, authorId, metaTag, autoMetaTag, createdBy })
  }

  async update(id: string, {title, content, thumbnailId, categoryId, authorId, metaTag, autoMetaTag }: CreateOrUpdatePostDto) {
    return this.httpService.put<PostDetailDto>(`/admin/v1/post/${id}`, {title, content, thumbnailId, categoryId, authorId, metaTag, autoMetaTag })
  }

  async published(id: string) {
    return this.httpService.put(`/admin/v1/post/${id}/published`)
  }

  async unPublished(id: string) {
    return this.httpService.put(`/admin/v1/post/${id}/unpublished`)
  }

  async distroy(id: string) {
    return this.httpService.delete(`/admin/v1/post/${id}`)
  }
}