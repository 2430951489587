// import { Injectable } from '@angular/core'

// import { HttpService } from './http.service'
// import { PageDto, PageResultDto, FindPageDto, PageSettingDto, CreateOrUpdatePageDto } from '@api/dto'

// @Injectable()
// export class PageService {
//   constructor(
//     private httpService: HttpService) {
//   }
//   async find(findPageDto: FindPageDto) {
//     return await this.httpService.get<PageResultDto<PageDto>>('/admin/v1/page', findPageDto)
//   }

//   async findOne(id: string) {
//     return this.httpService.get<PageDto>(`/admin/v1/page/${id}`)
//   }

//   async create({title, content, metaTag, autoMetaTag }: CreateOrUpdatePageDto) {
//     return this.httpService.post<PageSettingDto>(`/admin/v1/page`, {title, content, metaTag, autoMetaTag })
//   }

//   async update(id: string, {title, content, metaTag, autoMetaTag }: CreateOrUpdatePageDto) {
//     return this.httpService.put<PageSettingDto>(`/admin/v1/page/${id}`, {title, content, metaTag, autoMetaTag })
//   }

//   async enabled(id: string) {
//     return this.httpService.put(`/admin/v1/page/${id}/enabled`)
//   }

//   async disabled(id: string) {
//     return this.httpService.put(`/admin/v1/page/${id}/disabled`)
//   }

//   async delete(id: string) {
//     return this.httpService.delete(`/admin/v1/page/${id}`)
//   }
// }

import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PageDto, UpdatePageDto } from '../dto/page.dto'

import { PageResultDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'
@Injectable()
export class PageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/admin/v1/page', pagingDto)
  }

  async findOne(pageId: string) {
    return this.httpService.get<PageDto>(`/admin/v1/page/${pageId}`)
  }

  async update(pageId: string, {title, content, imageId, metaTag, autoMetaTag }: UpdatePageDto) {
    return await this.httpService.put<PageDto>(`/admin/v1/page/${pageId}`, {title, content, imageId, metaTag, autoMetaTag })
  }
}