import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PageResultDto, CategoryDto, FeaturedCategoryDto } from '@api/dto'

@Injectable()
export class FeaturedCategoryService {
  constructor(
    private httpService: HttpService
  ) {}

  async reOrder(categoryIds: string[]) {
    return await this.httpService.put<Boolean>('/admin/v1/category/featured/reorder', { categoryIds })
  }

  async create({ categoryId }: FeaturedCategoryDto) {
    return await this.httpService.post<Boolean>('/admin/v1/category/featured', { categoryId })
  }

  async find() {
    return await this.httpService.get<PageResultDto<CategoryDto>>('/admin/v1/category/featured')
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/category/featured/${id}`)
  }
}