import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { FaqDto, PageResultDto, FindFaqDto, FaqDetailDto, CreateOrUpdateFaqDto } from '@api/dto'

@Injectable()
export class FaqService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findFaqDto: FindFaqDto) {
    return await this.httpService.get<PageResultDto<FaqDto>>('/admin/v1/faq', findFaqDto)
  }

  async findOne(id: string) {
    return this.httpService.get<FaqDetailDto>(`/admin/v1/faq/${id}`)
  }

  async create({question, answer, enabled, sortOrder }: CreateOrUpdateFaqDto) {
    return this.httpService.post<FaqDetailDto>(`/admin/v1/faq`, {question, answer, enabled, sortOrder })
  }

  async update(id: string, {question, answer, enabled, sortOrder}: CreateOrUpdateFaqDto) {
    return this.httpService.put<FaqDetailDto>(`/admin/v1/faq/${id}`, {question, answer, enabled, sortOrder })
  }

  async enabled(id: string) {
    return this.httpService.put(`/admin/v1/faq/${id}/enabled`)
  }

  async disabled(id: string) {
    return this.httpService.put(`/admin/v1/faq/${id}/disabled`)
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/faq/${id}`)
  }
}