import * as _ from 'lodash'

import { Injectable } from '@angular/core'
import { PagingDto } from '@api/dto/paging.dto'
import { CertificationDetailDto, CertificationDto, CreateOrUpdateCertificationDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class CertificationService {
  constructor(
    private httpService: HttpService) {
  }

  // ===============================================================================================
  // get Methods
  // ===============================================================================================

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<CertificationDto>>(`/admin/v1/certification`, pagingDto)
  }

  async findOneCertification(id: string) {
    return await this.httpService.get<CertificationDetailDto>(`/admin/v1/certification/${id}`)
  }
  
  // ===============================================================================================
  // post Methods
  // ===============================================================================================

  async create({title, enabled, thumbnailId, sortOrder }: CreateOrUpdateCertificationDto) {
    return await this.httpService.post<CertificationDto>(`/admin/v1/certification`, {title, enabled, thumbnailId, sortOrder})
  }

  // ===============================================================================================
  // delete and update Methods
  // ===============================================================================================

  async deleteCertificationItem(id: string) {
    return await this.httpService.delete(`/admin/v1/certification/${id}`, id)
  }

  async update(id: string, {title, enabled, thumbnailId, sortOrder }: CreateOrUpdateCertificationDto) {
    return await this.httpService.patch<CertificationDetailDto>(`/admin/v1/certification/${id}`, {title, enabled, thumbnailId, sortOrder })
  }

  // ===============================================================================================
  // Image hadler
  // ===============================================================================================

  async saveThumbnail(id: string, fileId: string) {
    return this.httpService.put(`/admin/v1/certification/${id}` + '/thumbnail', {fileId})
  }

}
