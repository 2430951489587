import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { CreateOrUpdateProductTypeDto, FindProductTypeDto, PageResultDto, ProductTypeDetailDto, ProductTypeDto } from '@api/dto'

@Injectable()
export class ProductTypeService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findProductTypeDto: FindProductTypeDto) {
    return await this.httpService.get<PageResultDto<ProductTypeDto>>('/admin/v1/product-type', findProductTypeDto)
  }

  async findOne(id: string) {
    return this.httpService.get<ProductTypeDetailDto>(`/admin/v1/product-type/${id}`)
  }

  async create({ name, weight, iconImageId, featureImageId, sortOrder }: CreateOrUpdateProductTypeDto) {
    return this.httpService.post<ProductTypeDetailDto>(`/admin/v1/product-type`, {name, weight, iconImageId, featureImageId, sortOrder})
  }

  async update(id: string, { name, weight, iconImageId, featureImageId, sortOrder }: CreateOrUpdateProductTypeDto) {
    return this.httpService.put<ProductTypeDetailDto>(`/admin/v1/product-type/${id}`, { name, weight, iconImageId, featureImageId, sortOrder })
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/product-type/${id}`)
  }
}