import * as _ from 'lodash'

import { Injectable } from '@angular/core'
import { PagingDto } from '@api/dto/paging.dto'
import { ReviewDetailDto, ReviewDto, PageResultDto, UpdateReviewDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class ReviewService {
  constructor(
    private httpService: HttpService) {
  }

  // ===============================================================================================
  // get Methods
  // ===============================================================================================

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<ReviewDto>>(`/admin/v1/customer-reviews`, pagingDto)
  }

  async findOne(reviewId: string) {
    return await this.httpService.get<ReviewDetailDto>(`/admin/v1/customer-reviews/${reviewId}`)
  }
  
  // ===============================================================================================
  // post Methods
  // ===============================================================================================

  async create(review: ReviewDto) {
    return await this.httpService.post<ReviewDetailDto>(`/admin/v1/customer-reviews`, review)
  }

  // ===============================================================================================
  // delete and update Methods
  // ===============================================================================================

  async deleteReviewItem(reviewId: string) {
    return await this.httpService.delete(`/admin/v1/customer-reviews/${reviewId}`, reviewId)
  }

  async update(reviewId: string, UpdateReview: UpdateReviewDto) {
    return await this.httpService.patch<UpdateReviewDto>(`/admin/v1/customer-reviews/${reviewId}`, UpdateReview)
  }

  // ===============================================================================================
  // Image hadler
  // ===============================================================================================

  async saveImage(reviewId: string, fileId: string) {
    return this.httpService.put(`/admin/v1/customer-reviews/${reviewId}` + '/image', {fileId})
  }
}
