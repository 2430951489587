import { Injectable } from '@angular/core'
import { CreateFeaturedProductDto, PageResultDto, FeaturedProductDto, UpdateFeaturedProductDto,  } from '@api/dto'

import { HttpService } from './http.service'

@Injectable()
export class FeaturedProductService {
  constructor(
    private httpService: HttpService
  ) {}

  async reOrder(productIds: string[]) {
    return await this.httpService.put<Boolean>('/admin/v1/product/featured/reorder', { productIds })
  }

  async create({ productId }: CreateFeaturedProductDto) {
    return await this.httpService.post<Boolean>('/admin/v1/product/featured', { productId })
  }

  async find() {
    return await this.httpService.get<PageResultDto<FeaturedProductDto>>('/admin/v1/product/featured')
  }

  async update(id: string, {name, thumbnailId}: UpdateFeaturedProductDto) {
    return this.httpService.put<Boolean>(`/admin/v1/product/featured/${id}`, {name, thumbnailId})
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/product/featured/${id}`)
  }
}