import * as _ from 'lodash'

import { Injectable } from '@angular/core'
import { PagingDto } from '@api/dto/paging.dto'
import { BannerDetailDto, BannerDto, PageResultDto, UpdateBannerDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class BannerService {
  constructor(
    private httpService: HttpService) {
  }

  // ===============================================================================================
  // get Methods
  // ===============================================================================================

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<BannerDto>>(`/admin/v1/banner`, pagingDto)
  }

  async findOne(bannerId: string) {
    return await this.httpService.get<BannerDetailDto>(`/admin/v1/banner/${bannerId}`)
  }
  
  // ===============================================================================================
  // post Methods
  // ===============================================================================================

  async create(banner: BannerDto) {
    return await this.httpService.post<BannerDetailDto>(`/admin/v1/banner`, banner)
  }

  // ===============================================================================================
  // delete and update Methods
  // ===============================================================================================

  async deleteBannerItem(bannerId: string) {
    return await this.httpService.delete(`/admin/v1/banner/${bannerId}`, bannerId)
  }

  async update(bannerId: string, UpdateBanner: UpdateBannerDto) {
    return await this.httpService.patch<UpdateBannerDto>(`/admin/v1/banner/${bannerId}`, UpdateBanner)
  }

  // ===============================================================================================
  // Image hadler
  // ===============================================================================================

  async saveImage(bannerId: string, fileId: string) {
    return this.httpService.put(`/admin/v1/banner/${bannerId}` + '/image', {fileId})
  }

  async saveMobileImage(bannerId: string, fileId: string) {
    return this.httpService.put(`/admin/v1/banner/${bannerId}` + '/mobileImage', {fileId})
  }
}
