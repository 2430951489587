import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { EventDto, PageResultDto, FindEventDto, EventDetailDto, CreateOrUpdateEventDto, FindMaterialDto, MaterialDto, ResultDto } from '@api/dto'
import { CreateOrUpdateMaterialDto, CreateOrUpdateMaterialItemDto, MaterialDetailDto } from '@api/dto/material.dto'

@Injectable()
export class MaterialService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findMaterialDto: FindMaterialDto) {
    return await this.httpService.get<PageResultDto<MaterialDto>>('/admin/v1/material', findMaterialDto)
  }

  async findOne(materialId: string) {
    return this.httpService.get<MaterialDetailDto>(`/admin/v1/material/${materialId}`)
  }

  async create({title, imageId, bannerImageId }: CreateOrUpdateMaterialDto) {
    return this.httpService.post<MaterialDetailDto>(`/admin/v1/material`, {title, imageId, bannerImageId })
  }

  async update(id: string, {title, imageId, bannerImageId}: CreateOrUpdateMaterialDto) {
    return this.httpService.put<MaterialDetailDto>(`/admin/v1/material/${id}`, { title, imageId, bannerImageId })
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/material/${id}`)
  }

  async createMaterialItem(materialId: string ,{ thumbnailId, detail }: Partial<CreateOrUpdateMaterialItemDto>)  {
    return this.httpService.post<ResultDto>(`/admin/v1/material/${materialId}/items`, { 
      thumbnailId,
      detail   
    })
  }

  async updateMaterialItem(materialId: string, materialItemId: string, UpdateMaterialItem: CreateOrUpdateMaterialItemDto) {
    return await this.httpService.put<ResultDto>(`/admin/v1/material/${materialId}/items/${materialItemId}`, UpdateMaterialItem)
  }

  async deleteMaterialItem(materialId:string, materialItemId: string) {
    return this.httpService.delete(`/admin/v1/material/${materialId}/items/${materialItemId}`)
  }
}