import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, FileService, UserService, ProfileService, PostService, CategoryService, SettingService, FeaturedCategoryService, BannerService, StoreService, ProductService, ProductTypeService, ProductCategoryService, OnlineStoreService, FaqService, FeaturedProductService, MaterialService } from '@api/services'
import { ApiModuleOptions } from './api.module-options'
import { FeaturedPostService } from '../services/featured-post.service'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    CategoryService,
    PostService,
    ProductService,
    ProductTypeService,
    ProductCategoryService,
    SettingService,
    BannerService,
    FeaturedPostService,
    FeaturedCategoryService,
    FeaturedProductService,
    StoreService,
    OnlineStoreService,
    FaqService,
    MaterialService,
    AuthStore,
    AuthQuery
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}