import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PageResultDto, FindOnlineStoreDto, OnlineStoreDto, OnlineStoreDetailDto, CreateOrUpdateOnlineStoreDto } from '@api/dto'

@Injectable()
export class OnlineStoreService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findOnlineStoreDto: FindOnlineStoreDto) {
    return await this.httpService.get<PageResultDto<OnlineStoreDto>>('/admin/v1/online-store', findOnlineStoreDto)
  }

  async findOne(id: string) {
    return this.httpService.get<OnlineStoreDetailDto>(`/admin/v1/online-store/${id}`)
  }

  async create({ name, linkUrl, imageId }: CreateOrUpdateOnlineStoreDto) {
    return this.httpService.post<OnlineStoreDetailDto>(`/admin/v1/online-store`, {name, linkUrl, imageId })
  }

  async update(id: string, { name, linkUrl, imageId }: CreateOrUpdateOnlineStoreDto) {
    return this.httpService.put<OnlineStoreDetailDto>(`/admin/v1/online-store/${id}`, { name, linkUrl, imageId })
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/online-store/${id}`)
  }
}