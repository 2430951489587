import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { EventDto, PageResultDto, FindEventDto, EventDetailDto, CreateOrUpdateEventDto } from '@api/dto'

@Injectable()
export class EventService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findEventDto: FindEventDto) {
    return await this.httpService.get<PageResultDto<EventDto>>('/admin/v1/event', findEventDto)
  }

  async findOne(id: string) {
    return this.httpService.get<EventDetailDto>(`/admin/v1/event/${id}`)
  }

  async create({title, content, thumbnailId, metaTag, autoMetaTag }: CreateOrUpdateEventDto) {
    return this.httpService.post<EventDetailDto>(`/admin/v1/event`, {title, content, thumbnailId, metaTag, autoMetaTag })
  }

  async update(id: string, {title, content, thumbnailId, metaTag, autoMetaTag }: CreateOrUpdateEventDto) {
    return this.httpService.put<EventDetailDto>(`/admin/v1/event/${id}`, {title, content, thumbnailId, metaTag, autoMetaTag })
  }

  async enabled(id: string) {
    return this.httpService.put(`/admin/v1/event/${id}/enabled`)
  }

  async disabled(id: string) {
    return this.httpService.put(`/admin/v1/event/${id}/disabled`)
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/event/${id}`)
  }
}