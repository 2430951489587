import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PromotionDto, PromotionDetailDto, FindPromotionDto, CreateOrUpdatePromotionDto, PageResultDto } from '@api/dto'

@Injectable()
export class PromotionService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findEventDto: FindPromotionDto) {
    return await this.httpService.get<PageResultDto<PromotionDto>>('/admin/v1/promotion', findEventDto)
  }

  async findOne(id: string) {
    return this.httpService.get<PromotionDetailDto>(`/admin/v1/promotion/${id}`)
  }

  async create({title, content, thumbnailId, startDate, endDate, metaTag, autoMetaTag }: CreateOrUpdatePromotionDto) {
    return this.httpService.post<PromotionDetailDto>(`/admin/v1/promotion`, {title, content, thumbnailId, startDate, endDate, metaTag, autoMetaTag })
  }

  async update(id: string, {title, content, thumbnailId, startDate, endDate, metaTag, autoMetaTag }: CreateOrUpdatePromotionDto) {
    return this.httpService.put<PromotionDetailDto>(`/admin/v1/promotion/${id}`, {title, content, startDate, endDate, thumbnailId, metaTag, autoMetaTag })
  }

  async enabled(id: string) {
    return this.httpService.put(`/admin/v1/promotion/${id}/enabled`)
  }

  async disabled(id: string) {
    return this.httpService.put(`/admin/v1/promotion/${id}/disabled`)
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/promotion/${id}`)
  }
}