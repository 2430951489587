import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { CreateOrUpdateProductCategoryDto, FindProductCategoryDto, PageResultDto, ProductCategoryDetailDto, ProductCategoryDto } from '@api/dto'

@Injectable()
export class ProductCategoryService {
  constructor(
    private httpService: HttpService) {
  }
  async find(findProductCategoryDto: FindProductCategoryDto) {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>('/admin/v1/product-category', findProductCategoryDto)
  }

  async findOne(id: string) {
    return this.httpService.get<ProductCategoryDetailDto>(`/admin/v1/product-category/${id}`)
  }

  async create({ name, iconImageId, activeIconImageId, sortOrder, hasType }: CreateOrUpdateProductCategoryDto) {
    return this.httpService.post<ProductCategoryDetailDto>(`/admin/v1/product-category`, {name, iconImageId, activeIconImageId, sortOrder, hasType})
  }

  async update(id: string, { name, iconImageId, activeIconImageId, sortOrder, hasType }: CreateOrUpdateProductCategoryDto) {
    return this.httpService.put<ProductCategoryDetailDto>(`/admin/v1/product-category/${id}`, { name, iconImageId, activeIconImageId, sortOrder, hasType })
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/product-category/${id}`)
  }
}